<div
  [ngClass]="{
    'card': true,
    'expanded-card': isDisplaySystemAccessNotification || mfaSetup,
  }"
>
  <div
    [ngClass]="{
      'expanded-container': isDisplaySystemAccessNotification || mfaSetup
    }"
  >
    <div
      [ngClass]="{
        'expanded-form-container': isDisplaySystemAccessNotification || mfaSetup
      }"
    >
      <app-logo-container [src]="src" />
      @if (subHeading) {
        <h2 class="sub-heading">{{ subHeading }}</h2>
      }
      @if (!!_httpErrorService.httpError()) {
        <div class="error-container">
          <fa-icon [icon]="faCircleExclamation"></fa-icon>
          <span class="error-message">
            {{ _httpErrorService.httpError() }}
          </span>
        </div>
      }
      <ng-content></ng-content>
    </div>
    @if (isDisplaySystemAccessNotification) {
      <div class="long-diving-line"></div>
      <div class="form-container">
        <h1 class="notification-header">
          {{ LABEL_CONSTANT.SYSTEM_ACCESS_NOTIFICATION }}
        </h1>
        <div class="note-container">
          <div [innerHTML]="systemAccessNotification"></div>
        </div>
      </div>
    }
  </div>
</div>
